import React, { useEffect, useState, useRef } from "react";
import './App.css';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  const ruta = "https://api.carvalue.market";
  const [brands, setBrands] = useState([{}]);
  const [models, setModels] = useState([{}]);
  const [brand_id, setBrand] = useState("");
  const [show, setShow] = useState(false);
  const [datos, setDatos] = useState({
    brand: '', model: '',
    year: '', km: '', condition: 'Bueno', name: '', lastname: '', phone: '', city: 'Caracas', mail: '', comments: 'info', gearbox: 'N/A', transmission: 'N/A', error: '', success: ''
  })
  const [catchaValido, cambiarCaptchaValido] = useState(null);

  useEffect(() => {
    const fetching = async () => {

      const { data } = await axios.post(ruta + `/api/brands/all`);

      setBrands(data);
      setBrand(data[0]._id)

    };
    fetching();
  }, []);

  useEffect(() => {
    const fetchingModels = async () => {
      const { data } = await axios.post(ruta + `/api/models/all`);
      const selectedModels = data.filter((d) => d.brand_id === brand_id);
      setModels(selectedModels);
    };
    fetchingModels();
  }, [brand_id]);

  const onChangeBrand = (e) => {

    const selectbrand = brands.filter((d) => d._id === e)[0];
    //setBrandName(selectbrand.name);

    setBrand(e);

    setDatos({
      ...datos,
      ['brand']: selectbrand.name,
      ['brand_id']: selectbrand._id
    })

  }

  const onChangeModel = (e) => {

    const select = models.filter((d) => d._id === e)[0];
    //setModelName(select.name);
    //setModel(e);
    setDatos({
      ...datos,
      ['model']: select.name,
      ['model_id']: select._id
    })
  }



  const submitHandler = (e) => {
    e.preventDefault();

    if (captcha.current.getValue()) {
      cambiarCaptchaValido(true);
      const fetchingSubmit = async () => {
        const { data } = await axios.post(ruta + `/api/quotes/`, datos);
        if (data.error) {
          
          setDatos({
            ...datos,
            ['error']: data.message
          })
          return false;
        } else {
          window.parent.location.href = "https://carvalue.market/gracias/";
          setShow(true);
          setDatos({
            ...datos,
            ['success']: data.message
          })
          return false;
        }
      };
      fetchingSubmit();

    } else {
      cambiarCaptchaValido(false);
    }
  };

  const handleInputChange = (event) => {

    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })

  }


  const handleInputChangeNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remover caracteres no numéricos
    setDatos({
      ...datos,
      [e.target.name]: numericValue
    })


  }

  const captcha = useRef(null);

  function onChange(value) {
    if (captcha.current.getValue()) {
    }
  }


  return (
    <div className="App">
      <form onSubmit={submitHandler}>
        <div className=" container">

          <div className="row">
            <div className='col'>
              <strong>DATOS PERSONALES</strong>
              <hr></hr>
              <div className="form-group">
                <label for="exampleInputEmail1" >Nombre</label>
                <input type="text" className="form-control" id="name" name="name" aria-describedby="emailHelp" onChange={handleInputChange} placeholder="Nombre" required></input>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Apellido</label>
                <input type="text" className="form-control" id="lastname" name="lastname" onChange={handleInputChange} placeholder="Apellido" required></input>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Telefono</label>
                <input type="text" className="form-control" id="phone" name="phone" onChange={handleInputChange} placeholder="Telefono" required></input>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Ciudad</label>
                <select className="form-control" id="city" name="city" onChange={handleInputChange}>
                  <option selected value="Seleccion">Seleccione</option>
                  <option value="Caracas">Caracas</option>
                  <option value="Caracas">Otra</option>
                </select>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Correo</label>
                <input type="email" className="form-control" id="mail" name="mail" onChange={handleInputChange} placeholder="correo" required></input>
              </div>
              <br></br>
              <strong>DATOS DEL VEHICULO</strong>
              <hr></hr>
              <div className="form-group">
                <label for="exampleInputPassword1">Marca</label>

                <select className="form-control" name="brand" id="brand" onChange={(e) => onChangeBrand(e.target.value)}>
                  <option selected value="Seleccion">Seleccione</option>
                  {brands.map(marcas => (
                    <option value={marcas._id}>{marcas.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Model</label>
                <select className="form-control" name="model" id="model" onChange={(e) => onChangeModel(e.target.value)}>
                  <option selected value="Seleccion">Seleccione</option>
                  {models.map(modelo => (
                    <option value={modelo._id}>{modelo.name}</option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label for="exampleInputPassword1">Año</label>
                    <select className="form-control" id="year" name="year" onChange={handleInputChange}>
                      <option selected value="Seleccion">Seleccione</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2022">2021</option>

                    </select>
                  </div>
                </div>
                {/* <div className="col">
                  <div className="form-group">
                    <label for="exampleInputPassword1">Caja</label>
                    <select className="form-control" id="gearbox" name="gearbox" onChange={handleInputChange}>
                      <option selected value="Seleccion">Seleccione</option>
                      <option value="Automatica">Automatica</option>
                      <option value="Sincronico">Sincronico</option>
                      <option value="Secuencial">Secuencial</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label for="exampleInputPassword1">Transmision</label>
                    <select className="form-control" id="transmission" name="transmission" onChange={handleInputChange}>
                      <option selected value="Seleccion">Seleccione</option>
                      <option value="4x4">4x4</option>
                      <option value="4x2">4x2</option>
                      <option value="N/A">N/A</option>
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Kilometraje</label>
                <input type="number" value={datos.km} onChange={handleInputChangeNumber}  min="0" max="200000" className="form-control" id="km" name="km" placeholder="*Solo aceptamos vehiculos con menos de 200.000km" required></input>
              </div>

              <div className="form-group">
                <label for="exampleInputPassword1">Estado del vehiculo</label>
                <select className="form-control" id="condition" name="condition" onChange={handleInputChange}>
                  <option selected value="Seleccion">Seleccione</option>
                  <option value="Bien">Bien</option>
                  <option value="Muy Bien">Muy Bien</option>
                  <option value="Excelente">Excelente</option>
                </select>
              </div>
            </div>


            <div className="form-group">
              <label for="exampleFormControlTextarea1">Comentarios</label>
              <textarea className="form-control" name="comments" id="exampleFormControlTextarea1" onChange={handleInputChange} rows="4"></textarea>
            </div>


            <div className="alert alert-default" role="alert">

              <div className="clearfix"></div>
              <strong>Bien:</strong> <small>Tu carro tiene algunos detalles de latoneria y pintura reparables y no presenta problemas mecanicos importantes.</small>
              <div className="clearfix"></div>
              <div className="clearfix"></div>
              <strong>Muy Bien:</strong><small> Tu carro tiene detalles de latoneria y pintura menores y está en excelentes condiciones mecánicas.</small>
              <div className="clearfix"></div>
              <strong>Excelente:</strong> <small>Tu carro parece nuevo y esta en excelentes condiciones mecánicas.</small>
              <div className="clearfix"></div>
            </div>

            <div className="row">


              <div className="col-lg-4 col-sm-4 offset-sm-3 offset-lg-4 text-center">
                <div className="form-group">
                  <div className="recaptcha text-center">
                    <ReCAPTCHA
                      ref={captcha}
                      sitekey="6LfreLYiAAAAAIa3OkVJSXWMN4sMVh8KKk0zQ5GD"
                      onChange={onChange}
                      className="offset-lg-2 offset-sm-3"
                    />
                    {catchaValido === false && <div className="alert alert-danger" role="alert">
                      Por favor verifica el captcha
                    </div>}
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <br></br>
                      <button type="submit" disabled={show} className="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                  <div className="row">
                    <br></br>
                    {datos.error && <div className="col"><div className="alert alert-danger" role="alert">
                      {datos.error}
                    </div></div>}
                    {datos.success && <div className="col"><div className="alert alert-primary" role="alert">
                      {datos.success}
                    </div></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}


export default App;
